import React, { useState, useEffect } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import IQReviewAnswers from "../../components/iq-test/ReviewAnswers.ar"
import IQTest from "../../components/iq-test/IQTest.ar"
import TestResult from "../../components/iq-test/TestResult.ar"
import { backendHost } from '../../config';
import SelectLevelPage from "../../components/select-level"

let iqQuestions = [], setIqQuestions = d => iqQuestions = d;

const MarsQuizPage = () => {

  const [showTest, setShowTest] = useState(true);
  const [isCalculating, setIsCalculating] = useState(false);
  const [showReviewAnswers, setShowReviewAnswers] = useState(false);

  const [iqTestResponses, setIqTestResponses] = useState([])

  const [difficulty, setDifficultyHook] = useState(undefined);

  const setDifficulty = diff => {
    // get data from api
    fetch(`${backendHost}/questions?difficulty=${diff}`)
    .then(response => response.json()) // parse JSON from request
    .then(resultData => {
      setIqQuestions(resultData);
      setDifficultyHook(diff);
    });
  }

  const formSubmit = (form) => {
    const answers = iqQuestions.map((item) => {
      console.log(form.elements[`option-${item.id}`].value);
      item['selectedOption'] = form.elements[`option-${item.id}`].value;
      return item
    })

    setIqTestResponses(answers);
    setIsCalculating(true);
    setTimeout(() => {
      setIsCalculating(false);
    }, 500)
    setShowTest(false);
  }

  // useEffect(() => {
  //   // get data from api
  //   fetch(`https://www.admin-emarsmission.aspgulf.net/iq-test-questions?hide=0`)
  //     .then(response => response.json()) // parse JSON from request
  //     .then(resultData => {
  //       setIqQuestions(resultData)
  //     })
  // }, [])

  if (difficulty !== undefined) {

    return (
      <Layout lang="ar">
        <SEO title="Mars Quiz" lang="ar" />
        <main>
          {
            showTest ?       
                <IQTest iqQuestions={iqQuestions} formSubmit={formSubmit} changeLevel={() => { setDifficulty(undefined); }} difficulty={difficulty} />

              : isCalculating ?

                <section style={{ marginTop: "-100px", position: "relative" }}>
                  <div className="large-container pb6" style={{ maxWidth: "900px", margin: "auto", backgroundColor: "#262626" }}>Calculate Result</div>
                </section>

                : showReviewAnswers ?
                  <IQReviewAnswers iqTestResponses={iqTestResponses}  goBack={() => {
                    setShowReviewAnswers(false);
                  }}/>
                  :
                  <section id="iq-result">
                    <TestResult
                      iqTestResponses={iqTestResponses}
                      showReviewAnswers={() => {
                        setShowReviewAnswers(true);
                      }}
                      takeTestAgain={() => {
                        window.location.reload();
                      }}
                      shareResult={() => {
                        // do something
                      }}
                    />
                  </section>
          }

        </main>
      </Layout>
    );
  } else {
    return (
      <SelectLevelPage lang={'ar'} setDifficulty={setDifficulty} />
    )
  }
}

export default MarsQuizPage
