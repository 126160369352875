import React, { useState, useEffect } from "react"
import imgQuizCover from "../../assets/images/gen-mars/quiz-result/cover.png"

const TestResult = ({ iqTestResponses, showReviewAnswers, shareResult, takeTestAgain }) => {

    const [percentage, setPercentage] = useState(0);
    const [correctAnswers, setCorrectAnswers] = useState(0);
    const [totalQuestions, setTotalQuestions] = useState(iqTestResponses.length);

    const [pageURL, setPageURL] = useState(window.location.href);

    useEffect(() => {
        setPageURL(window.location.href);
    }, [window.location.href]);

    const calculateResult = () => {
        let correct = 0;
        iqTestResponses.forEach((question) => {
            if (question.selectedOption == question.correct_option.id) {
                correct++;
            }
        });

        setCorrectAnswers(correct);
        setTotalQuestions(iqTestResponses.length);
        setPercentage(Math.round(correct / iqTestResponses.length * 100));
    }

    useEffect(() => {
        calculateResult();
    }, [iqTestResponses])

    return <div dir="ltr" className="uk-cover-container" data-uk-height-viewport>
        <img src={imgQuizCover} alt="" data-uk-cover />
        <div className="uk-overlay uk-position-cover header-overlay flex justify-center items-center">
            <div className="mt6">
                <h2 className="relative uk-text-center">
                نتيجة اختبار تحدي المريخ
            <div className="text-underline-heavy" style={{ marginLeft: "auto", marginRight: "auto" }}></div>
                </h2>
                <div className="pa3 mt5 uk-text-center ba b-white" style={{ backgroundColor: "rgba(0,0,0,.52)", backdropFilter: "saturate(180%) blur(5px)", WebkitBackdropFilter: "saturate(180%) blur(5px)", maxWidth: "400px", minWidth: "300px" }}>
                    <div className="font-nostromo uk-text-bold text-3d" style={{ fontSize: "8rem", color: "#fff", WebkitTextStroke: "2px #000" }}>
                        {percentage}<sup>%</sup>
                    </div>
                    <p className="text-light pb0 mb0">
                        {/* You've Got */}
                    </p>
                    <div className="uk-text-large uk-text-bold text-highlight-brown">
                        <span>{correctAnswers} </span><span> / </span> <span>{totalQuestions}</span> لقد حصلت على
            </div>
                    {/* <p className="text-light uk-text-small">
                        لقد كان أداؤك أفضل من %<span>70</span> ممن خضعوا لهذا الاختبار
            </p> */}
                </div>
                <div className="uk-background-secondary flex justify-between">
                    <button className="uk-button uk-button-secondary uk-button-large" style={{ padding: "0 10px" }} onClick={takeTestAgain}>
                    !حاول مجدداً
                    </button>
                    <button className="uk-button uk-button-secondary uk-button-large" style={{ padding: "0 10px" }} onClick={showReviewAnswers}>
                    مراجعة إجاباتك            
                    </button>
            <button className="uk-button uk-button-secondary uk-button-large" style={{ padding: "0 10px" }} onClick={() => {
                        const hiddenInput = document.getElementById('hidden-input');
                        hiddenInput.textContent = `Hey! I have scored ${percentage}% in Mars Quiz. Show me what you can do on ${pageURL}`;

                        const element = document.getElementById('my-id');
                        const UIkit = window.UIkit;

                        if (UIkit) {
                            UIkit.modal(element).show();
                        }
                    }}>
                        <span uk-icon="icon: social"></span>  مشاركة
            </button>
                </div>
            </div>

        </div>

        <div id="my-id" data-uk-modal>
            <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical" style={{ background: "none", width: "auto" }}>
                <h2 className="relative">
                    Copy and share with your friends!
                <div className="text-underline-heavy"></div>
                </h2>
                <p className="text-white">
                    <textarea type="text" id="hidden-input" readOnly
                    rows="3" cols="50"
                        style = {{
                            width: '100%',
                            background: 'transparent',
                            color: 'white',
                            border: 'none',
                            outline: 'none',
                            resize: 'none',
                            lineHeight: '34px'
                        }}
                    ></textarea>
                </p>
                <button className="uk-button uk-button-default" type="button" onClick={() => {
                    const copyText = document.getElementById('hidden-input');
                    /* Select the text field */
                    copyText.select();

                    /* Copy the text inside the text field */
                    document.execCommand("copy");

                    const element = document.getElementById('my-id');
                        const UIkit = window.UIkit;

                        if (UIkit) {
                            UIkit.modal(element).hide();
                        }
                }}>COPY</button>
            </div>
        </div>
    </div>

}

export default TestResult;