import React from "react"
import imgQuizCover from "../../assets/images/gen-mars/mars-quiz/cover.png"

const IQQuestionsView = ({ questions }) => {
    return (
      <>
        {
          questions.map((question, index) => (
            <fieldset className="uk-fieldset">
              <div className="uk-margin flex flex-column flex-row-ns flex-start">
                <div className="w-100 w-10-ns pa2">
                  <span className="uk-text-lead text-highlight-brown stacked-card-count font-nostromo uk-text-bold" style={{ fontWeight: "500" }}>
                    {index + 1}
                  </span>
                </div>
                <div className="w-100 w-90-ns pa2">
                  <div className="uk-text-large text-light mb4">
                    {question.arabicText}
                  </div>
  
                  {
                    question.options.map(option => (
                      <>
                        <label><input className="uk-radio" type="radio" name={`option-${question.id}`} value={option.id} /> {option.arabicText}</label>
                        <br/><br/>
                      </>
                    ))
                  }
                  {/* <label className="w-100 text-highlight-brown"><input className="uk-radio" type="radio" name="radio" /> 2</label><br /><br />
                <label className="w-100 text-highlight-red"><input className="uk-radio" type="radio" name="radio" /> 4</label><br /><br />
                <label><input className="uk-radio" type="radio" name="radio" /> 5</label><br /><br />
                <label><input className="uk-radio" type="radio" name="radio" /> 6</label> */}
                </div>
  
              </div>
              <hr className="custom-hr" style={{ borderColor: "black" }} />
            </fieldset>
          ))
        }
  
  
      </>
    )
  }

const IQTest = ({ iqQuestions, formSubmit, changeLevel, difficulty }) => {
    
    return <>
    <div dir="rtl" className="uk-cover-container" data-uk-height-viewport="offset-top: true; offset-bottom: 40" style={{ borderBottom: "5px solid #fff" }}>
      {/* <canvas width="100%" height="100%"></canvas> */}
      <img src={imgQuizCover} alt="" data-uk-cover style={{transform: "translate(50%,-50%)"}} />
      <div className="uk-overlay uk-position-cover header-overlay"></div>
    </div>
    <section id="about-emm" style={{ marginTop: "-100px", position: "relative" }}>
    <div className="large-container pb6">
      <div className="standard-container standard-bg">
        <div className="relative">
          <h2 className="relative uk-text-center mb5">
          تحدي المريخ          
          <div className="text-underline-heavy" style={{ marginLeft: "auto", marginRight: "auto" }}></div>
          <div style={{
            padding: '10px',
            background: 'var(--highlight-color)',
            borderRadius: '10px',
            color: '#fff',
            margin: 'auto',
            display: 'inline-block',
            fontSize: '20px',
            marginTop: '10px'
          }}>
            <b>{difficulty.split("")[1]} ابدأ التحدي:</b>&nbsp;
            <a onClick={() => { changeLevel(); }} style={{color: 'rgba(0,0,0,0.4)'}}>تغيير</a>
          </div>
          </h2>
        </div>
        <div className="pa4-ns" style={{ maxWidth: "900px", margin: "auto", backgroundColor: "#262626" }}>
          <form id="iqForm" onSubmit={(evt) => {
            evt.preventDefault();
            const form = document.getElementById('iqForm');
            formSubmit(form)
          }}>
            <IQQuestionsView questions={iqQuestions} />
            <div className="uk-margin uk-text-center">
              <button className="uk-button uk-button-default" type="submit" data-uk-toggle="target: #modal-example">إرسال الإجابات</button>
            </div>
          </form>

        </div>
      </div>
    </div>
  </section>
  </>
}

export default IQTest
