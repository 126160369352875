import React from "react"
import {Link} from "gatsby"

import Layout from "./layout"
import SEO from "./seo"
import { string } from "prop-types"

const strings = {
  title: {
    en: 'Take quiz',
    ar: 'ابدأ التحدي'
  },
  levels: {
    en: [
      'Level 1',
      'Level 2',
      'Level 3'
    ],
    ar: [
      'المستوى 1',
      'المستوى 2',
      'المستوى 3'
    ]
  },
  button: {
    en: 'Take quiz',
    ar: 'ابدأ التحدي'
  }
}

const SelectLevelPage = ({ lang, setDifficulty }) => (
  <Layout lang={lang}>
    <SEO title="Home" lang={lang} />
    <main>
      <div className="standard-container">
        <h2 className="relative uk-text-center mt6 mb5">
            {strings.title[lang]}
          <div className="text-underline-heavy" style={{marginLeft: "auto", marginRight: "auto"}}></div>
        </h2>
        <div className="uk-margin">
          <div className="uk-margin uk-card uk-card-secondary uk-padding">
            <div className="flex flex-column flex-row-ns justify-between w-100">
              <div className="w-100 w-80-ns">
                <h2 className="text-light uk-margin-remove-bottom">
                {strings.levels[lang][0]}
                </h2>
              </div>
              <div className="w-100 w-20-ns">
                <button className="uk-button uk-button-default" onClick={() => { setDifficulty('L1') }}>
                  <span>{strings.button[lang]}</span>
                </button>
              </div>
            </div>
          </div>
          <div className="uk-margin uk-card uk-card-secondary uk-padding">
            <div className="flex flex-column flex-row-ns justify-between w-100">
              <div className="w-100 w-80-ns">
                <h2 className="text-light uk-margin-remove-bottom">
                {strings.levels[lang][1]}
                </h2>
              </div>
              <div className="w-100 w-20-ns">
                <button className="uk-button uk-button-default" onClick={() => { setDifficulty('L2') }}>
                  <span>{strings.button[lang]}</span>
                </button>
              </div>
            </div>
          </div>
          <div className="uk-margin uk-card uk-card-secondary uk-padding">
            <div className="flex flex-column flex-row-ns justify-between w-100">
              <div className="w-100 w-80-ns">
                <h2 className="text-light uk-margin-remove-bottom">
                {strings.levels[lang][2]}
                </h2>
              </div>
              <div className="w-100 w-20-ns">
                <button className="uk-button uk-button-default" onClick={() => { setDifficulty('L3') }}>
                  <span>{strings.button[lang]}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </Layout>
)

export default SelectLevelPage
